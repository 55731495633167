<template>

  <!-- Search Articles View -->
  <j-page
    title="Articles"
    :subtitle="pageSubtitle"
    :loading="loading"
    id="search-articles-view"
  >

    <!-- Actions -->
    <template #actions>

      <!-- Search Bar -->
      <j-search
        :query="query.q"
        open
        @search="handleSearch"
        @clear="handleClear"
      />

    </template>

    <!-- Main Area - Search results -->
    <v-col>

      <!-- Article Query -->
      <ArticleTable
        v-if="query"
        :heading="heading"
        :articles="query"
        date-label="Published"
        date-field="published_at"
        :count="false"
        @paginate="handlePaginate"
      />
    </v-col>

  </j-page>
</template>

<script>

// Components
import ArticleTable from '@/components/widgets/articles/ArticleTable'

// Vuex
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapGetters, mapActions, mapMutations } = createNamespacedHelpers('articles')

export default {
  name: 'SearchArticlesView',
  // -------------
  // Components ==
  // -------------
  components: { 
    ArticleTable
  },
  // -------------
  // Middleware ==
  // -------------
  beforeRouteEnter(_to, _from, next) {
    next(vm => {
      vm.NEW_QUERY()
    })
  },
  // -----------
  // Metadata ==
  // -----------
  metaInfo() {
    return {
      title: `Articles [matching ${this.query.q}]`
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    // State
    ...mapState([
      'query'
    ]),
    // Getters
    ...mapGetters([
      'loading'
    ]),
    // UI
    pageSubtitle () {
      return this.query.q ? `matching &laquo;${this.query.q}&raquo;` : ''
    },
    heading () {
      if (this.query.q) {
        return this.query.loading
          ? 'Showing ...'
          : `Showing ${this.query.get('start')} - ${this.query.get('end')} of ${this.query.get('count')}`
      } else {
        return 'Enter a search term...'
      }
    },
    // Helpers
    pageQuery () {
      return {
        p: this.query.p,
        q: this.query.q
      }
    }
  },
  // --------
  // Watch ==
  // --------
  watch: {
    pageQuery (pageQuery) {
      this.fetchQuery(pageQuery)
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    // Actions
    ...mapActions([
      'fetchQuery'
    ]),
    // Mutations
    ...mapMutations([
      'NEW_QUERY'
    ]),

    // -----------
    // Handlers ==
    // -----------

    // Search
    handleSearch (q) {
      this.NEW_QUERY()
      this.query.q = q
      this.query.p = 1
    },

    // Paginate
    handlePaginate(p) {
      this.query.p = p
    },

    // Clear
    handleClear() {
      this.$router.push({ name: 'articles-index' })
    }
  }
}
</script>
