<template>
  <j-table
    class="article-table"
    :listing="articles"
    items="articles"
    :heading="heading"
    :subhead="subhead"
    :short-pagination="shortPagination"
    :show-pagination="showPagination"
    :count="count"
    @paginate="paginate"
  >
    <!-- Actions -->
    <template #actions>
      <slot name="actions" />
    </template>

    <!-- Header -->
    <template
      v-if="showTableHeader"
      #header
    >
      <tr>
        <th v-if="show('date')">
          {{ dateLabel }}
        </th>
        <th v-if="show('section')">
          Section
        </th>
        <th width="70%">
          Title
        </th>
        <th
          v-if="show('byline')"
          width="15%"
        >
          Byline
        </th>
        <th
          v-if="show('stage')"
          width="15%"
        >
          Status
        </th>
      </tr>
    </template>

    <!-- Listing -->
    <template #listing>
      <tr
        v-for="article in articles.getModels()"
        :key="article.tag19"
      >
        <!-- Date -->
        <td
          v-if="show('date')"
          nowrap="nowrap"
        >
          {{ article[dateField] | shortDate }}
        </td>

        <!-- Section -->
        <td v-if="show('section')">
          <j-label :section="article.section" :icon="article.featured ? 'star' : null" />
        </td>

        <!-- Title -->
        <td width="70%">
          <j-label
            :title="article.releaseDate"
            color="primary"
            small
            class="mr-1"
          />
          <strong><router-link
            :to="{ name: 'articles-view', params: { tag19: article.tag19 }}"
            v-html="article.title"
          /></strong>
          <j-counter :count="article.comment_count" />
        </td>

        <!-- Byline -->
        <td
          v-if="show('byline')"
          width="15%"
          class="byline"
          v-html="article.byline"
        />

        <!-- Status -->
        <td
          v-if="show('stage')"
          width="15%"
          v-html="article.stage"
        />
      </tr>
    </template>

    <!-- Empty Listing -->
    <template
      v-if="$slots.empty"
      #empty
    >
      <slot name="empty" />
    </template>

    <!-- Footer -->
    <template
      v-if="$slots.footer"
      #footer
    >
      <tr>
        <td :colspan="columnCount">
          <slot name="footer" />
        </td>
      </tr>
    </template>
  </j-table>
</template>

<script>

export default {
  name: 'ArticleTable',
  // -------------
  // Properties ==
  // -------------
  props: {
    articles: {
      type: Object,
      default: null
    },
    heading: {
      type: String,
      default: ''
    },
    subhead: {
      type: String,
      default: ''
    },
    count: {
      type: Boolean,
      default: true
    },
    hide: {
      type: Array,
      default: () => []
    },
    dateLabel: {
      type: String,
      default: 'Published'
    },
    dateField: {
      type: String,
      default: 'published_at'
    },
    shortPagination: {
      type: Boolean,
      default: false
    },
    showPagination: {
      type: Boolean,
      default: true
    },
    showTableHeader: {
      type: Boolean,
      default: true
    }
  },
  // -----------
  // Computed ==
  // -----------
  computed: {
    columnCount () {
      return 5 - this.hide.length
    }
  },
  // ----------
  // Methods ==
  // ----------
  methods: {
    show(column) {
      if (this.hide) {
        return !this.hide.includes(column)
      } else {
        return true
      }
    },
    paginate(page) {
      this.$emit('paginate', page)
    }
  }
}
</script>

<style lang="scss">

.byline {
  white-space: nowrap;
}

</style>
